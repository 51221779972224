import { animatedCollapse, animatedExpand } from '@/helpers/animated';

pageLoad('pages_root', 'info_pages_show', () => {
  if (!document.body.className.includes('v4')) { return; }

  $('article.top_faq_questions .b-faq-v4').on('click', ({ currentTarget }) => {
    const node = currentTarget.querySelector('.body');

    if (currentTarget.classList.contains('is-expanded')) {
      animatedCollapse(node);
      currentTarget.classList.remove('is-expanded');
    } else {
      animatedExpand(node);
      currentTarget.classList.add('is-expanded');
    }
  });
});
